
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ForgotPasswordChangeRequestDto } from "../../core/types/UserTypes";

export default defineComponent({
  name: "reset-your-password",
  props: ["query"],
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const forgotPasswordChangeRequestDto = ref<ForgotPasswordChangeRequestDto>({
      email: props.query.email,
      token: props.query.token,
      password: "",
    });
    const submitButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const changePasswordValidator = Yup.object().shape({
      //Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character
      newpassword: Yup.string()
        .required()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/
        )
        .label("Password"),
      confirmpassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    //Form submit function
    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        store
          .dispatch(
            Actions.FORGOT_PASSWORD_RESET,
            forgotPasswordChangeRequestDto.value
          )
          .then(() => {
            Swal.fire({
              text: "Confirmed. Your password has been reset.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Continue to Sign-In",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push("/sign-in");
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "There was a problem changing your password.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
        updatePasswordButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    return {
      updatePassword,
      changePasswordValidator,
      forgotPasswordChangeRequestDto,
      updatePasswordButton,
      submitButton,
    };
  },
});
