<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      id="kt_signin_change_password"
      class="form"
      novalidate="novalidate"
      @submit="updatePassword()"
      :validation-schema="changePasswordValidator"
    >
      <div class="row mb-6">
        <div class="col-lg-12">
          <div
            class="
              d-flex
              flex-center flex-column flex-column-fluid
              p-0
              pb-lg-10
            "
          >
            <!--begin::Logo-->
            <a href="#" class="mb-0">
              <img
                alt="Snappy Popcorn"
                src="/media/logos/Primary-Snappy-Logo.svg"
                width="300"
              />
            </a>
            <!--end::Logo-->

            <router-view></router-view>
          </div>
          <div class="fv-row mb-4">
            <label for="newpassword" class="form-label fs-6 fw-bolder mb-3"
              >New Password</label
            >
            <Field
              type="password"
              class="
                form-control form-control-lg form-control-solid
                fw-bold
                fs-6
              "
              name="newpassword"
              id="newpassword"
              v-model="forgotPasswordChangeRequestDto.password"
            />
            <small
              >Must Contain 6 Characters, One Uppercase, One Lowercase, One
              Number and one special case Character</small
            >
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="newpassword" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="fv-row mb-0">
            <label for="confirmpassword" class="form-label fs-6 fw-bolder mb-3"
              >Confirm New Password</label
            >
            <Field
              type="password"
              class="
                form-control form-control-lg form-control-solid
                fw-bold
                fs-6
              "
              name="confirmpassword"
              id="confirmpassword"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="confirmpassword" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <button
          id="kt_password_submit"
          type="submit"
          ref="updatePasswordButton"
          class="btn btn-primary me-2 px-6"
        >
          <span class="indicator-label"> Reset Password </span>
          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ForgotPasswordChangeRequestDto } from "../../core/types/UserTypes";

export default defineComponent({
  name: "reset-your-password",
  props: ["query"],
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const forgotPasswordChangeRequestDto = ref<ForgotPasswordChangeRequestDto>({
      email: props.query.email,
      token: props.query.token,
      password: "",
    });
    const submitButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const changePasswordValidator = Yup.object().shape({
      //Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character
      newpassword: Yup.string()
        .required()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/
        )
        .label("Password"),
      confirmpassword: Yup.string()
        .min(6)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    //Form submit function
    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        store
          .dispatch(
            Actions.FORGOT_PASSWORD_RESET,
            forgotPasswordChangeRequestDto.value
          )
          .then(() => {
            Swal.fire({
              text: "Confirmed. Your password has been reset.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Continue to Sign-In",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push("/sign-in");
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "There was a problem changing your password.",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
        updatePasswordButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    return {
      updatePassword,
      changePasswordValidator,
      forgotPasswordChangeRequestDto,
      updatePasswordButton,
      submitButton,
    };
  },
});
</script>
